import * as React from "react"
// import Layout from "@lekoarts/gatsby-theme-jodie/src/components/layout"

const NotFound = () => (
  <div>
    <div style={{ textAlign: `center` }}>
      <h1>404</h1>
      <p>Page not found.</p>
    </div>
  </div>
)

export default NotFound
